*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Mandali', sans-serif;
    line-height: 120%;
}
#navbar{
    position: fixed;
    top: -90px;
    width: 100%;
    transition:all  0.3s;
    z-index: 9999;
    background: #2c2c2cee;

}
#navbar>a  {
    float: left;
    color: #f1f1f1;
    padding: 1.9vh;
    text-decoration: none;
    font-size: 5vh;
    margin-left: 3vh;
    height: 8%;
    text-shadow: -0.4vh 0 blueviolet;
    transition:0.9s ;
} 
#navbar>a:hover{
    text-shadow: 0.4vh 0 blueviolet;
}
nav{
    display: flex;
    justify-content: space-evenly;
    justify-content:right;
}
nav a{
    background: #444;
    color: #f1f1f1;
    padding: 15px;
    text-decoration: none;
    font-size: 3vh;
    margin: 1vh 3vh ;
} 
nav a:hover{
    color: #000; 
} 
#nav-mob{
    display: none;
}
#homebuss{
    height: 100vh;
    width: 100%;
    background:linear-gradient(rgba(0, 0, 0, 0.741), rgba(0, 0, 0, 0.762)),url(https://www.shoes-report.ru/upload/iblock/862/q848uk5knbd4e3sh5ymcf44rjs582ugr.jpg);
    background-repeat:no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
}
#homebuss>h1{
    background-color: transparent;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50% ,-50%);
    font-size: 24vh;
    color: #f1f1f1;
    text-shadow: -0.8vh 0 blueviolet;
    transition:0.9s ;
}
#homebuss>h1:hover{ 
    text-shadow: 0.8vh 0 blueviolet;
}
#homebuss>h2{
    background-color: transparent;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50% ,-50%);
    font-size: 4vh;
    color: #eee;
}
#homebuss>a{
    background-color: transparent;
    position: absolute;
    bottom: 0.5%;
    left: 50%;
    transform: translate(-50% ,-50%);
    background: transparent;
    font-size: 5vh;
    color: #eee;
    transition:ALL 0.4s ;
    text-decoration: none;
    border: solid 1px white;
    padding: 0.6vh 2vh 1.5vh ;
    border-radius: 2vh;
    background: #111;
}
#homebuss>a:hover{
    color: #111;
    background: #eee;
}
#homebuss>#span{
    background-color: transparent;
    position: absolute;
    bottom: 20%;
    left: 50%;
    width: 100%;
    text-align: center;
    transform: translate(-50% ,-50%);
    background: transparent;
    font-size: 5vh;
    color: #eee;
    transition:0.9s ;
}
#homebuss>#span>span{
    margin-left: 4vh;
    margin-right: 4vh;
}
#home2{
width: 100%;
height: auto;
position: relative;
display:block;
background: #f1f1f1;
}
#home2>#img1{
    width: 100%;
    height: 70vh;
    background: #e6b5b5;
    position: relative;

}
#home2>#img1>h1{
    position: absolute;
    background: #e5c1c1;
    top: 20vh;
    left: 30vh;
    width: 66vh;
    text-align: center;
    background: transparent;
    color: whitesmoke;
    font-size: 6vh;
    text-transform: uppercase;
    transition: 0.6s;
    text-shadow: -0.5vh 0 #f7a9ab;
}
#home2>#img1>h1:hover{
    text-shadow: 0.5vh 0 #f7a9ab;
}
#home2>#img1>p{
    position: absolute;
    top: 30vh;
    left: 27vh;
    width: 66vh;
    text-align: center;
    color: #333;
    font-size: 2.5vh;
    border: solid 0 transparent;
    box-shadow: 0 0 3px 0.5px #333;
    border-radius: 1vh;
    padding: 20px 15px 20px 7px;

}
#home2>#img1>img{
    position: absolute;
    top: 22vh;
    right: 24vh;
    width: 40vh;
    border: solid 2px #f7a9ab;
    border-radius: 1vh;
   box-shadow: 0vh 0vh 20px rgb(26, 25, 25);
}
#home2>#img2{
    width: 100%;
    height: 70vh;
    background-color: #817e7e;
    position: relative;
}
#home2>#img2>h1{
    position: absolute;
    background: #e5c1c1;
    top: 20vh;
    right: 32vh;
    width: 66vh;
    text-align: center;
    background: transparent;
    color: whitesmoke;
    font-size: 6vh;
    text-transform: uppercase;
    transition: 0.6s;
    text-shadow: -0.5vh 0 #da9732;
}
#home2>#img2>h1:hover{
    text-shadow: 0.5vh 0 #da9732;
}
#home2>#img2>p{
    position: absolute;
    top: 30vh;
    right: 22vh;
    width: 66vh;
    text-align: center;
    color: #333;
    font-size: 2.5vh;
    border: solid 0 transparent;
    box-shadow: 0 0 3px 0.5px #333;
    border-radius: 1vh;
    padding: 20px 15px 20px 7px;
}
#home2>#img2>img{
    position: absolute;
    top: 12vh;
    left: 33vh;
    width: 40vh;
    border: solid 2px #f7a9ab;
    border-radius: 1vh;
   box-shadow: 0vh 0vh 20px rgb(26, 25, 25);
}
#home2>#img3{
    width: 100%;
    height: 70vh;
    background: #e2e2e2;
    position: relative;

}
#home2>#img3>h1{
    position: absolute;
    background: #e2e2e2;
    top: 20vh;
    left: 30vh;
    width: 66vh;
    text-align: center;
    background: transparent;
    color: white;
    font-size: 6vh;
    text-transform: uppercase;
    transition: 0.6s;
    text-shadow: -0.5vh 0 #00000042;
}
#home2>#img3>h1:hover{
    text-shadow: 0.5vh 0 #0000006b;
}
#home2>#img3>p{
    position: absolute;
    top: 30vh;
    left: 27vh;
    width: 66vh;
    text-align: center;
    color: #333;
    font-size: 2.5vh;
    border: solid 0 transparent;
    box-shadow: 0 0 3px 0.5px #333;
    border-radius: 1vh;
    padding: 20px 15px 20px 7px;

}
#home2>#img3>img{
    position: absolute;
    top: 28vh;
    right: 24vh;
    width: 40vh;
    border: solid 2px #f7a9ab;
    border-radius: 1vh;
   box-shadow: 0vh 0vh 20px rgb(26, 25, 25);
}
#home2>#img4{
    width: 100%;
    height: 70vh;
    background-color: #817e7e;
    position: relative;
}
#home2>#img4>h1{
    position: absolute;
    background: #e5c1c1;
    top: 20vh;
    right: 22vh;
    width: 66vh;
    text-align: center;
    background: transparent;
    color: whitesmoke;
    font-size: 6vh;
    text-transform: uppercase;
    transition: 0.6s;
    text-shadow: -0.5vh 0 #da9732;
}
#home2>#img4>h1:hover{
    text-shadow: 0.5vh 0 #da9732;
}
#home2>#img4>p{
    position: absolute;
    top: 30vh;
    right: 22vh;
    width: 66vh;
    text-align: center;
    color: #333;
    font-size: 2.5vh;
    border: solid 0 transparent;
    box-shadow: 0 0 3px 0.5px #333;
    border-radius: 1vh;
    padding: 20px 15px 20px 7px;
}
#home2>#img4>img{
    position: absolute;
    top: 12vh;
    left: 33vh;
    width: 40vh;
    border: solid 2px #f7a9ab;
    border-radius: 1vh;
   box-shadow: 0vh 0vh 20px rgb(26, 25, 25);
}
#lo{
    display: grid;
    min-height: 100vh;
    width: 90%;
    grid-template-columns: repeat(5 , 1fr);
    margin-left: 5%;
    margin-top: 1%;
}
#lo>h1{
    font-size: 25vh;
    color: lightseagreen;
}
#card{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1.5vh 2vh;
    box-shadow: 0 0 20px 1px #eee;
    border-radius: 2vh;
    background: #fffeff;
}
#card>img{
    width: 32vh;
    height: 36vh;
    padding: 2.5vh;
    position: relative;
}
#card>#card-descripion{
   width: 32vh;
   height: 36vh;
   margin: 1.5vh 2vh;
   position: absolute;
   background: linear-gradient(transparent ,transparent,#ffffffab, #ffffffc4,#ffffffda , #ffffffdf );
   opacity:0 ;
   transition:0.6s ;
}
#card>#card-descripion:hover{
    opacity: inherit;
}
#card>#card-descripion>h5{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    font-size: 2vh;
    transform: translate(-50% ,-50%);
    color: #000;
    text-align: center;
    text-shadow: 0 0 1px 2px black;
}
#card>#card-descripion>h6{
    position: absolute;
    top: 68%;
    left: 50%;
    width: 90%;
    text-align: center;
    transform:translate(-50% ,-50%);
    color: rgb(255, 18, 18);
    font-size: 3vh;
    text-shadow: 0 0 2px 1px black;
}
#card>#card-descripion>a{
    font-size: 2vh;
    color: #444;
    text-decoration: none;
    text-transform: uppercase;
    width: auto;
    text-align: center;
    font-weight: bold;
    position: absolute;
    left: 50%;
    bottom: 1%;
    transform: translate(-50% ,-50%);
    border: solid 0.2vh rgb(66, 66, 66);
    background: #eee;
    border-radius: 2vh;
    padding: 1vh 1.9vh;
    transition: 0.5s;
}
#card>#card-descripion>a:hover{
    background: #333;
    color: #fff;

}






#footer-link{
    display: flex;
    justify-content: space-around;
    padding-top: 4vh;
    background: #f1ecec;
    height: 37vh;

}
#footer1>h3{
    font-size: 4vh;
    text-transform: uppercase;
}
#footer1>a{
    display: block;
    margin-top: 2vh;
    font-size: 2.5vh;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    color: #888;
}
#footer1>a:hover{
    text-decoration: underline;
    color: #333;
    
}
#footer2>h3{
    font-size: 4vh;
    text-transform: uppercase;
}
#footer2>a{
    display: block;
    margin-top: 2vh;
    font-size: 2.5vh;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    color: #888;
}
#footer2>a:hover{
    text-decoration: underline;
    color: #333;
}
#footer3>h3{
    font-size: 4vh;
    text-transform: uppercase;
}
#footer3>a{
    display: block;
    margin-top: 2vh;
    font-size: 2.5vh;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
}
#tw{
    color: rgb(27, 102, 172);
}
#ti{
    color: rgb(12, 12, 12);
}
#yo{
    color: rgb(187, 22, 22);
}
#fa{
    color: rgb(68, 31, 168);
}
#footer3>a:hover{
    color: #333;
}
#copy{
    text-align: center;
    padding: 1vh 0 2vh 0;
    background: #000000;
    color: orangered;
    font-size: 3vh;

}


@media  screen and (max-width: 1150px) {
    #home2>#img1,
    #home2>#img2,
    #home2>#img3,
    #home2>#img4{
        position: relative;
    }

    #home2>#img1>h1,
    #home2>#img3>h1{
        position: absolute;
        left: 7vh;
        
    }
    #home2>#img1>p,
    #home2>#img3>p{
        position: absolute;
        left: 7vh;

        
    }
    #home2>#img1>img,
    #home2>#img3>img{
        position: absolute;
        right: 7vh;
    }
    #home2>#img2>h1,
    #home2>#img4>h1{
        position: absolute;
        right: 7vh ;    
    }
    #home2>#img2>p,
    #home2>#img4>p{
        position: absolute;
        right: 7vh ;    
        
    }
    #home2>#img2>img,
    #home2>#img4>img{
        position: absolute;
        left: 7vh;
    }

}
@media screen and (max-width: 817px) {
    #home2>#img1,
    #home2>#img2,
    #home2>#img3,
    #home2>#img4{
     position: relative;
     overflow: hidden;
    }

    #home2>#img1>h1,
    #home2>#img2>h1,
    #home2>#img3>h1,
    #home2>#img4>h1{
        position: absolute;
        left: 60%;
        width: 90vh;
        margin-left: 5vh;
        z-index: 99;
        transform: translate(-50% , -50%)
    

}
    #home2>#img1>p,
    #home2>#img2>p,
    #home2>#img3>p,
    #home2>#img4>p{
        position: absolute;
        left: 5vh;
        top: 58%;
        width: 90%;   
        z-index: 99;
        color: #160f0f;
        background: #eeeeee71;   
        max-height: 177px; 
        overflow: hidden;
    }
    #home2>#img1>img,
    #home2>#img2>img,
    #home2>#img3>img,
    #home2>#img4>img{
        position: absolute;
        left: 20%;
        top: 31%;
        width: 25vh;
        transform: translate(-50% , -50%);
    }
}
@media screen and (max-width:780px) {
    #navbar>a{
        width: 100%;
        text-align: center;
        margin-left: 0;
    }
    #navbar>nav{
        display: none;
    }
    #nav-mob{
        display: flex;
        position: fixed;
        bottom: -1px;
        align-items: center;
        justify-content: space-around;
        background: #000000dc;
        width: 100%;
    }
    #nav-mob>a{
        font-size: 5vh;
        padding-top: 1.6vh;
        color: #5e5e5e;
    }
    #nav-mob>a:hover{
        color: #fff;
        border-radius: 50%;
}
    #homebuss>h1{
        font-size: 15vh;
    }
    #homebuss>h2{
        text-align: center;
        width: 100%;
    }
    #homebuss>#span{
        width: 70%;
        bottom: 15vh;
    }
    #homebuss>a{
        text-align: center;
        width: 60%;
}
    #homebuss>{
        justify-content: center; 
    }
    #home2>#img1>h1,
    #home2>#img2>h1,
    #home2>#img3>h1,
    #home2>#img4>h1{
        top: 30%;
        left: 50%;
        width: 100%;
        text-align: center;
        margin-left: 0;
}
    #home2>#img1>img,
    #home2>#img2>img,
    #home2>#img3>img,
    #home2>#img4>img{
       display: none;
    }
    #home2>#img1>p,
    #home2>#img2>p,
    #home2>#img3>p,
    #home2>#img4>p{
        width: 85%;
        left: 7.5%;
        
    }
    #home2>#img1{
        background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxtY4V3GJOGtlXwdnvo1_ScoxhHoCxNYZSfg&usqp=CAU");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-bottom: solid white 2vh;

    }
    #home2>#img2{
        background-image:linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("https://www.thejoyjewels.com/cdn/shop/files/DSC3618.jpg?v=1691674922&width=3840");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-bottom: solid rgb(119, 117, 117) 2vh;
    }
    #home2>#img3{
        background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTygoqhvoQGlPY1dN9wT_gLegiW0-_Cn_XdGQ&usqp=CAU");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-bottom: solid rgb(136, 136, 136) 2vh;
    }
    #home2>#img4{
        background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("https://assets.myntassets.com/dpr_1.5,q_60,w_400,c_limit,fl_progressive/assets/images/22120556/2023/2/25/986d6f97-fe9b-487a-9c64-f577e0a32c491677314970316KhushalKwomenEmbroideryKurtaandpalazzowithdupattaset7.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-bottom: solid white 2vh;
    }
    #footer-link{
        display: block;
        height: auto;
        padding-bottom: 3vh;
    }
    #footer1>h3,
    #footer2>h3,
    #footer3>h3{
        text-align: center;
        width: 100%;
        background: #8d8d8d31;
        margin-top: 2vh;
        margin-bottom: 2vh;
        font-style: 7vh;
    }
    #lo{
        grid-template-columns: repeat(2 , 1fr);
        align-items: center;
    }
    #lo>h1{
        font-size: 9vh;
        margin-left: 8vh;
        
    }


}
